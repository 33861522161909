




















































































































import gsap from 'gsap'
import pin from '@/assets/images/pin360.png'

import { push } from '@/inc/utils'
import { Panorama, PanoramaItem, PanoramaClickEvent } from '@/inc/types'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'Personality360',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<Panorama>,
      required: true,
    },
  },

  setup(props) {
    const container = ref<HTMLElement | null>(null)
    const infospotIndex = ref<number | null>(null)
    const isOpen = ref(false)
    let canCloseInfo = true
    let viewer
    let isMouseDown = false
    let isDragging = false

    // Close infospot panel
    const closeInfo = () => {
      if (!canCloseInfo) {
        return
      }

      isOpen.value = false
      infospotIndex.value = null
    }

    // Get current position of the panorama (where the panorama 'looks')
    const getCurrentPosition = () => {
      // eslint-disable-next-line prefer-destructuring
      const { point } = viewer.raycaster.intersectObject(
        viewer.panorama,
        true
      )[0]

      const panoramaWorldPosition = viewer.panorama.getWorldPosition()

      return createVector3(
        -(point.x - panoramaWorldPosition.x),
        point.y - panoramaWorldPosition.y,
        point.z - panoramaWorldPosition.z
      )
    }

    // Helper function to replace creation of THREE.Vector3
    const createVector3 = (x: number, y: number, z: number) => ({
      x,
      y,
      z,
      // https://threejs.org/docs/#api/en/math/Vector3.length
      // eslint-disable-next-line no-mixed-operators
      length: Math.sqrt(x * x + y * y + z * z),
    })

    // Create Panolens panorama
    const createPanorama = (PANOLENS: any) => {
      // Create  viewer
      viewer = new PANOLENS.Viewer({
        container: container.value,
        controlButtons: [],
      })

      viewer.renderer.sortObjects = true
      viewer.renderer.antialias = true
      viewer.OrbitControls.minFov = 30
      viewer.OrbitControls.maxFov = 130

      // Set starting zoom
      // Parse and clamp value betwen 0 and 10
      const startingZoom = props.content.startingZoom
        ? gsap.utils.clamp(0, 10, parseInt(props.content.startingZoom, 10))
        : 4

      // Map starting zoom to the fov range
      viewer.getCamera().fov = gsap.utils.mapRange(
        // Original range is 0-10
        0,
        10,
        // New range is 30-130
        viewer.OrbitControls.minFov,
        viewer.OrbitControls.maxFov,
        // Value needs to be inverted: a startingZoom of 0 is the maximum fov
        10 - startingZoom
      )
      viewer.getCamera().updateProjectionMatrix()

      // Create panorama
      const panorama = new PANOLENS.ImagePanorama(props.content.picture)

      // Set initial position to 180deg
      panorama.addEventListener('load', () => {
        const degrees = props.content.startingPosition
          ? parseInt(props.content.startingPosition, 10)
          : 0
        viewer.getControl().rotateLeft(degrees * (Math.PI / 180))
        viewer.getControl().update()
      })

      // Add infospots
      const scale = window.innerWidth < 1024 ? 800 : 400
      const { radius } = panorama

      if (props.content.items) {
        props.content.items.forEach((item: PanoramaItem, i: number) => {
          if (!item.position) {
            return
          }

          try {
            const parsedPosition = JSON.parse(item.position)
            const position = createVector3(
              parseInt(parsedPosition.x, 10),
              parseInt(parsedPosition.y, 10),
              parseInt(parsedPosition.z, 10)
            )

            const infospot = new PANOLENS.Infospot(
              (scale * position.length) / radius,
              pin
            )

            infospot.name = item.displayTitle
            infospot.userData.index = i
            infospot.position.copy(position)
            infospot.addEventListener('click', onInfospotClick)
            panorama.add(infospot)
          } catch (e) {
            // Silently fail: the infospot won't be added
          }
        })
      }

      // Track drag event, and copy position on click
      container.value?.addEventListener('mousedown', e => onMouseDown(e))
      container.value?.addEventListener('touchstart', e => onMouseDown(e))

      viewer.add(panorama)
    }

    // Controls
    // Update zoom of the panorama (triggered by +- buttons)
    const updateZoom = (amplitude: number) => {
      const camera = viewer.getCamera()
      const currentZoom = camera.fov
      const canZoom =
        amplitude > 0
          ? currentZoom < viewer.OrbitControls.maxFov
          : currentZoom > viewer.OrbitControls.minFov

      if (canZoom) {
        gsap.to(camera, {
          fov: currentZoom + amplitude,
          onUpdate: () => {
            camera.updateProjectionMatrix()
          },
        })
      }

      push({
        event: 'environnement_360',
        interactionType: 'Controls',
        // eslint-disable-next-line camelcase, id-length
        click_360: 'Zoom',
      })
    }

    // Update x position of panorama (triggered by arrow buttons)
    const rotateX = (degree: number) => {
      viewer.OrbitControls.rotateLeft(degree)

      push({
        event: 'environnement_360',
        interactionType: 'Controls',
        // eslint-disable-next-line camelcase, id-length
        click_360: 'Arrows',
      })
    }

    // Update y position of panorama (triggered by arrow buttons)
    const rotateY = (degree: number) => {
      viewer.OrbitControls.rotateUp(degree)

      push({
        event: 'environnement_360',
        interactionType: 'Controls',
        // eslint-disable-next-line camelcase, id-length
        click_360: 'Arrows',
      })
    }

    // Handle click on infospot: open panel
    const onInfospotClick = (e: PanoramaClickEvent) => {
      if (!canCloseInfo) {
        return
      }

      e.target.focus()
      infospotIndex.value = e.target.userData.index
      canCloseInfo = false
      isOpen.value = true

      push({
        event: 'environnement_360',
        interactionType: 'Point',
        // eslint-disable-next-line camelcase, id-length
        content_360_value:
          props.content.items[infospotIndex.value as number].displayTitle,
      })

      setTimeout(() => (canCloseInfo = true), 500)
    }

    // Begin tracking drag by setting mouse down as true
    // Copy position if shift is pressed
    const onMouseDown = (event: MouseEvent | TouchEvent) => {
      isMouseDown = true

      // Add events for cursor move and release
      window.addEventListener('mouseup', onMouseUp)
      window.addEventListener('mousemove', onMouseMove)

      window.addEventListener('touchend', onMouseUp)
      container.value?.addEventListener('touchmove', onMouseMove)

      // If shift is pressed, copy position to clipboard
      if (process.env.VUE_APP_RELEASE !== 'production' && event.shiftKey) {
        const outputPosition = getCurrentPosition()

        navigator.clipboard
          .writeText(
            `{"x": ${outputPosition.x}, "y": ${outputPosition.y}, "z": ${outputPosition.z}}`
          )
          .catch(() => {
            console.warn('Copy to clipboard failed')
          })
      }
    }

    // Set dragging as true if mouse is down
    const onMouseMove = () => {
      if (isMouseDown) {
        isDragging = true
      }
    }

    // Push event to datalayer + reinit dragging params
    const onMouseUp = () => {
      push({
        event: 'environnement_360',
        interactionType: isDragging ? 'Drag end' : 'Click',
      })

      // Reinit vars
      isDragging = false
      isMouseDown = false

      // Remove event listeners
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchend', onMouseUp)
      container.value?.addEventListener('touchmove', onMouseMove)
    }

    onMounted(async () => {
      const PANOLENS = await import(
        /* webpackChunkName: 'vendor-panolens' */ 'panolens'
      ).then(module => module)

      createPanorama(PANOLENS)
    })

    return {
      container,
      infospotIndex,
      isOpen,
      closeInfo,
      updateZoom,
      rotateX,
      rotateY,
    }
  },
})
